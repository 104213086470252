<template>
  <div class="intelligent-life">
    <headPage />

    <!-- 一键实现智能化生活 -->
    <div style="position: reletive">
      <img
        style="display: block; width: 100%"
        src="../assets/image/image16.png"
      />
      <div class="topInfo">
        <div class="title">一键实现</div>
        <div class="title2">智能化场景生活</div>
        <div class="divider"></div>
        <div class="topImages">
          <img
            style="display: block; width: 100%"
            src="../assets/image/image24.png"
          />
          <img class="img2" src="../assets/image/image25.png" />
        </div>
      </div>
      <!-- 更便捷的智慧生活 -->
      <div style="position: relative" class="i2">
        <img
          style="display: block; width: 100%"
          src="../assets/image/image17-1.jpg"
          alt=""
        />
      </div>
      <!-- 开启舒适生活的一天 -->
      <div class="confotLive">
        <div class="i3">
          <p class="p1">为您开启</p>
          <p class="p2">舒适智能的一天</p>
        </div>
        <div class="wrapper">
          <swiper ref="mySwiper" style="height: 100%" :options="swiperOptions">
            <swiper-slide>
              <div class="bottom-pics">
                <img src="../assets/image/image21.jpg" alt="" />
                <div class="botdiv">
                  <p class="p3">晨起</p>
                  <p class="p4">
                    舒缓的音乐响起，一个私人定制的美好早晨开始了。
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="bottom-pics"
                :class="active == '0' ? 'swiperActive' : ''"
              >
                <img src="../assets/image/image22.jpeg" alt="" />
                <div class="botdiv">
                  <p class="p3">居家</p>
                  <p class="p4">
                    晨起后，先来一杯纯净安全水，与小朋友一起一键操控多种场景，感受便捷生活。
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="bottom-pics"
                :class="active == '1' ? 'swiperActive' : ''"
              >
                <img src="../assets/image/image23.jpeg" alt="" />
                <div class="botdiv">
                  <p class="p3">外出</p>
                  <p class="p4">手机24小时实时监控家中动态，安全放心。</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="bottom-pics"
                :class="active == '2' ? 'swiperActive' : ''"
              >
                <img src="../assets/image/image20.jpeg" alt="" />
                <div class="botdiv">
                  <p class="p3">入睡</p>
                  <p class="p4">
                    深夜，窗帘自动关闭，安防报警系统启动，在适宜的温湿度环境中入睡。
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="bottom-pics"
                :class="active == '3' ? 'swiperActive' : ''"
              >
                <img src="../assets/image/image19.jpeg" alt="" />
                <div class="botdiv">
                  <p class="p3">娱乐</p>
                  <p class="p4">
                    晚上，和爱人来个烛光晚餐，看场电影，享受影院级效果，尽情地享受生活。
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="bottom-pics"
                :class="active == '4' ? 'swiperActive' : ''"
              >
                <img src="../assets/image/image18.jpeg" alt="" />
                <div class="botdiv">
                  <p class="p3">办公</p>
                  <p class="p4">
                    午后，家中恒温恒温恒氧，调节合适的灯光效果，以最佳状态全情投入工作。
                  </p>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-scrollbar" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>

<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  components: {
    headPage,
    footPage,
  },
  data() {
    return {
      tran: 0,
      active: 0,
      swiperOptions: {
        slidesPerView: 3,
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true,
        },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        on: {
          transitionEnd: () => {
            console.log(this.page.activeIndex);
            this.active = this.page.activeIndex;
          },
        },
      },
    };
  },
  methods: {
    scroll(value) {
      this.tran = value;
    },
  },
  mounted() {},
  computed: {
    page() {
      return this.$refs["mySwiper"].$swiper;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .swiper-slide {
  z-index: 998;
  .botdiv {
    margin-top: 50px;
  }
}
/deep/ .swiper-slide-next {
  z-index: 9999;
}
/deep/ .swiper-container {
  padding-top: 40px;
}
.swiperActive {
  z-index: 9999;
  img {
    transform: scale(1.2);
    box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
    z-index: 9999;
  }
}
.swiper-scrollbar-drag {
  width: 50px;
}
.swiper-scrollbar {
  width: 350px;
  left: 50%;
  transform: translateX(-50%);
}
.wrapper {
  height: 500px;
  margin: 0 40px 40px 40px;
  img {
    width: 449px;
    height: 283px;
  }
  .p3 {
    margin-left: 20px;
    text-align: left;
    font-family: "SourceHanSansCN-Bold";
    font-size: 19px;
    color: #000;
    margin-top: 6px;
  }
  .p4 {
    margin-left: 20px;
    text-align: left;
    font-family: "SourceHanSansCN-Light";
    font-size: 15px;
    color: #000;
    width: 290px;
    margin-top: 11px;
  }
}
.img2 {
  display: block;
  width: 100%;
  position: absolute;
  top: 276px;
  width: 76px;
  left: 193px;
}
.left {
  position: absolute;
  width: 60px;
  height: 60px;
  right: 217px;
  top: 85px;
  cursor: pointer;
}
.right {
  position: absolute;
  width: 60px;
  height: 60px;
  right: 138px;
  top: 85px;
  cursor: pointer;
}
.topInfo {
  text-align: left;
  left: 130px;
  top: 233px;
  z-index: 999;
  position: absolute;
}
.title {
  font-size: 63px;
  color: #d0ac32;
  font-family: "SourceHanSansCN-Bold";
}
.title2 {
  font-family: "SourceHanSansCN-Light";
  font-size: 63px;
  color: #fff;
  margin-top: -10px;
}
.divider {
  height: 2px;
  background-color: #fff;
  width: 58px;
  margin-left: 6px;
  margin-top: 14px;
}
.topImages {
  width: 228px;
  margin-top: 42px;
  margin-left: 6px;
}
.i3 {
  text-align: left;

  margin-left: 133px;
}
.confotLive {
  padding-bottom: 60px;
  position: relative;
  padding-top: 86px;
  background-color: #f3e6c3;
  .p1 {
    font-family: "SourceHanSansCN-Bold";
    color: black;
    font-size: 37px;
  }
  .p2 {
    font-family: "SourceHanSansCN-Medium";
    color: black;
    font-size: 38px;
    margin-top: -13px;
  }
}
.i3-right {
  text-align: right;
  margin-right: 138px;
  margin-top: -102px;
  img {
    width: 140px;
  }
}
div::-webkit-scrollbar {
  width: 10px;
}
div::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.scrollx {
  overflow-x: scroll;
}

.inside {
  width: 322px;
  background-color: #7f7f7f;
  height: 15px;
  border-radius: 20px;
  height: 10px;
  margin: 0 auto;
}
.bottom {
  margin-top: 61px;
  text-align: left;
  padding-left: 137px;
  width: 2917px;
  transition: 0.3s;
  img {
    width: 449px;
    height: 283px;
  }
  .bottom-pics {
    z-index: 998;
    text-align: left;
    display: inline-block;
    margin-right: 17px;
    vertical-align: text-top;
    img {
      width: 449px;
      height: 283px;
    }
    .p3 {
      font-family: "SourceHanSansCN-Bold";
      font-size: 19px;
      color: #000;
      margin-top: 6px;
    }
    .p4 {
      font-family: "SourceHanSansCN-Light";
      font-size: 15px;
      color: #000;
      width: 290px;
      margin-top: 11px;
    }
  }
}
</style>
